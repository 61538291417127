import { useTranslation } from '@dreamstack/i18n'
import { ArrowLeft } from '@dreamstack/icons'
import { motion } from 'framer-motion'
import type { FunctionComponent } from 'react'
import tw from 'twin.macro'
import {
  AnimateIconTranslationLeft,
  BodyText,
  HeadingH1,
  PropertyIndexResultAreaInnerGrid,
  SimpleMuiButton,
  useAnimateIconTranslationLeft,
  usePropertySearchContext,
} from '../../index'
import { useNewsletterForm } from '../../newsletter/useNewsletterForm'

const StyledEmailField = tw.div`col-span-full`
const StyledLegalImprintField = tw.div`col-span-full mt-px-32 lg:(col-span-10)`
const StyledSubmitButton = tw.div`col-span-full mt-px-32`

export const PropertyFilterOverviewNoResults: FunctionComponent<React.PropsWithChildren<{}>> = () => {
  const t = useTranslation()

  const {
    EmailField,
    LegalImprintField,
    SubmitButton,
    FormProvider,
    formProps,
    formProviderProps,
    SuccessDialog,
  } = useNewsletterForm('light')

  const { onHoverStart, controls } = useAnimateIconTranslationLeft()

  const { setPropertySearchFilterValues } = usePropertySearchContext()

  const onGoBackClick = () => {
    setPropertySearchFilterValues({ cities: [], locations: [], regions: [] })
    window?.scrollTo(0, 0)
  }
  return (
    <PropertyIndexResultAreaInnerGrid>
      <div tw="col-span-full margin-bottom[-104px] md:(col-start-2 col-span-6 margin-bottom[-176px]) lg:(margin-bottom[-144px])">
        <HeadingH1>{t('accentro:formTexts.soldOut')}</HeadingH1>
        <BodyText tw="font-semibold mt-px-20 lg:mt-px-32">
          {t('accentro:formTexts.areWeAllowedToContactYou')}
        </BodyText>
        <BodyText tw="mt-px-32 lg:mt-px-48">
          {t('accentro:formTexts.newsletterBody')}
        </BodyText>
        <div tw="mt-px-32 lg:mt-px-48">
          <FormProvider {...formProviderProps}>
            <form {...formProps}>
              <div tw="grid grid-cols-12">
                <StyledEmailField>{EmailField}</StyledEmailField>
                <StyledLegalImprintField>
                  {LegalImprintField}
                </StyledLegalImprintField>
                <StyledSubmitButton>{SubmitButton}</StyledSubmitButton>
              </div>
            </form>
          </FormProvider>
          {SuccessDialog}
        </div>
        <div tw="mt-px-32">
          <motion.span onHoverStart={onHoverStart}>
            <SimpleMuiButton
              onClick={onGoBackClick}
              startIcon={
                <AnimateIconTranslationLeft animate={controls}>
                  <ArrowLeft />
                </AnimateIconTranslationLeft>
              }
              variant="text"
              color="primary"
            >
              {t('accentro:formTexts.backToProperties')}
            </SimpleMuiButton>
          </motion.span>
        </div>
      </div>
    </PropertyIndexResultAreaInnerGrid>
  )
}
