import { useUserIdWithLoading } from '@dreamstack/auth'
import { useTranslation } from '@dreamstack/i18n'
import { getOrCreateAnonymousId } from '@dreamstack/tracking/src/getOrCreateAnonymousId'
import { trpc } from '@dreamstack/trpc/client'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import map from 'lodash/map'
import take from 'lodash/take'
import Link from 'next/link'
import { useRouter } from 'next/router'
import type { FunctionComponent } from 'react'
import { useCallback, useMemo, useRef, useState } from 'react'
import { useGetPropertiesFilteredAndSorted } from '../../hooks/useGetPropertiesFilteredAndSorted'
import { SimpleMuiButton } from '../../index'
import { PropertyPreview } from '../PropertyPreview'
import { useGetPropertyUrl } from '../routing/useGetPropertyUrl'

const useStyles = makeStyles((theme) => ({
  loadMoreContainer: {
    width: '23rem',
    color: '#508090',
    borderColor: '#508090',
    padding: '12px 20px',
    '& span': {
      fontWeight: 600,
      fontSize: '20px',
    },
    '& a': {
      paddingRight: '1.25rem',
    },
    [theme.breakpoints.down('sm')]: {
      width: '19rem',
      padding: '12px',
      '& span': {
        fontSize: '16px',
      },
      '& a': {
        paddingRight: '0.5rem',
      },
    },
  },
}))

export const PropertyFilterOverviewResultProperties: FunctionComponent<
  React.PropsWithChildren<{}>
> = () => {
  const getPropertyUrl = useGetPropertyUrl()
  const { userId } = useUserIdWithLoading()
  const { mutateAsync: trackButtonClickServer } =
    trpc.anonymousTracking.trackEvent.useMutation()
  const router = useRouter()

  const { allPropertiesFilteredAndSorted } = useGetPropertiesFilteredAndSorted()
  //should be a multiple of the grid amount (like %12)
  const paginationSteps = 10

  const t = useTranslation()

  const [pagination, setPagination] = useState(paginationSteps)

  const loadMore = useCallback(() => {
    setPagination((p) => p + paginationSteps)
  }, [setPagination])
  const ref = useRef(null)
  const paginatedProperties = useMemo(
    () => take(allPropertiesFilteredAndSorted, pagination),
    [allPropertiesFilteredAndSorted, pagination]
  )

  const showLoadMoreButton = useMemo(
    () => pagination < allPropertiesFilteredAndSorted.length,
    [pagination, allPropertiesFilteredAndSorted]
  )
  const classes = useStyles()

  const trackClickCallToActionButton = async ({
    propertyId,
    city,
  }: {
    propertyId: number
    city: string | null
  }) => {
    const anonymousUserId = getOrCreateAnonymousId()
    await trackButtonClickServer({
      eventName: 'buttonClick',
      userId,
      anonymousUserId,
      data: {
        buttonAction: 'Immobilien Vorschau Kachel',
        url: router.asPath,
        additionalInfo: {
          propertyId,
          city,
        },
      },
    })
  }

  return (
    <>
      {!paginatedProperties?.length ? (
        <>
          {/* TODO: re-enable, when save search is implemented: */}
          {/* <BodyText>{t('accentro:propFilterNoMatchesBody')}</BodyText> */}
        </>
      ) : (
        map(paginatedProperties, (property, index) => {
          const { id, city } = property
          const href = getPropertyUrl({ city: city ?? undefined, id })
          return (
            <Link
              href={href}
              passHref
              key={href}
              onClick={() =>
                trackClickCallToActionButton({ propertyId: id, city })
              }
              onKeyDown={() =>
                trackClickCallToActionButton({ propertyId: id, city })
              }
            >
              <PropertyPreview
                propDetailsLayoutPosition={index % 2 === 0 ? 'right' : 'left'}
                key={id}
                property={property}
              />
            </Link>
          )
        })
      )}
      <div ref={ref}>
        {showLoadMoreButton && (
          <SimpleMuiButton
            onClick={loadMore}
            className={classes.loadMoreContainer}
          >
            <AddIcon fontSize="large" />
            {t('accentro:loadMore')}
          </SimpleMuiButton>
        )}
      </div>
    </>
  )
}
