import { useTranslation } from '@dreamstack/i18n'
import { Minus, Plus } from '@dreamstack/icons'
import { CircularProgress } from '@material-ui/core'
import React from 'react'
import type { FunctionComponent } from 'react'
import tw, { styled } from 'twin.macro'
import { useGetAllValidProperties } from '../../hooks/useGetAllValidProperties'
import { useGetPropertiesFilteredAndSorted } from '../../hooks/useGetPropertiesFilteredAndSorted'
import { GridWithGaps, HeadingH1, HeadingH4 } from '../../index'
import { usePropertySearchContext } from '../../next/PropertySearchContext'
import { PropertyFilter } from './PropertyFilter'
import { PropertyFilterSortMenu } from './PropertyFilterSortMenu'
import { PropertyFilterTextSummary } from './PropertyFilterTextSummary'

const PropertyIndexMobileFilterArea = tw.div`block py-px-24 md:(hidden)`
const PropertyIndexMobileFilterAreaToggle = tw.div`block py-px-24 cursor-pointer md:(hidden)`
const PropertyIndexMobileFilter = tw.div`px-px-16 mt-px-24 w-full bg-accentroWhite-full md:(hidden)`

const PropertyIndexResultAreaInnerGrid = styled(GridWithGaps)(() => [
  tw`grid-cols-12 md:(grid-cols-8) lg:(grid-cols-9)`,
])

const PropertyIndexFilterResultSubTitle = tw.div`col-start-1 col-span-12 md:(col-start-2 col-span-6) lg:(col-start-2 col-span-7)`

export const PropertyFilterOverviewResultHeading: FunctionComponent<React.PropsWithChildren<{
  mobileFilterOpen: boolean
  showNoResults: boolean
  setMobileFilterOpen: React.Dispatch<React.SetStateAction<boolean>>
}>> = ({ mobileFilterOpen, showNoResults, setMobileFilterOpen }) => {
  const { allPropertiesFilteredAndSorted, loading } =
    useGetPropertiesFilteredAndSorted()
  const { propertySearchFilterValues } = usePropertySearchContext()
  const t = useTranslation()
  const { allProperties } = useGetAllValidProperties()
  const filterSet =
    allProperties.length !== allPropertiesFilteredAndSorted.length

  return (
    <PropertyIndexResultAreaInnerGrid>
      <PropertyIndexFilterResultSubTitle>
        {(filterSet || loading) && !showNoResults && (
          <HeadingH1>
            {loading ? (
              <CircularProgress color="primary" />
            ) : (
              `${allPropertiesFilteredAndSorted.length} ${t(
                `accentro:rsTypes.${propertySearchFilterValues.typeOfImmo}`,
                {
                  count: allPropertiesFilteredAndSorted.length,
                }
              )}`
            )}
          </HeadingH1>
        )}
        <div tw="flex flex-1 flex-col items-start md:(justify-between) lg:(flex-row items-center)">
          {!showNoResults && (
            <HeadingH4>
              <PropertyFilterTextSummary />
            </HeadingH4>
          )}

          <PropertyIndexMobileFilter>
            <PropertyIndexMobileFilterAreaToggle
              onClick={() => setMobileFilterOpen((current) => !current)}
            >
              <div tw="flex justify-between items-center">
                <HeadingH4>{t('accentro:filter-results')}</HeadingH4>
                {mobileFilterOpen ? <Minus /> : <Plus />}
              </div>
            </PropertyIndexMobileFilterAreaToggle>

            {mobileFilterOpen && (
              <PropertyIndexMobileFilterArea>
                <PropertyFilter />
              </PropertyIndexMobileFilterArea>
            )}
          </PropertyIndexMobileFilter>
          {!showNoResults && (
            <div tw="mt-px-32 lg:(mt-0 text-right)">
              <PropertyFilterSortMenu />
            </div>
          )}
        </div>
      </PropertyIndexFilterResultSubTitle>
    </PropertyIndexResultAreaInnerGrid>
  )
}
