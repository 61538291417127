import dynamic from 'next/dynamic'
import type { FunctionComponent } from 'react'
import { useState } from 'react'
import tw, { styled } from 'twin.macro'
import { GridWithGaps } from '../../index'
import type { PropertySearchContextProviderOptions } from '../../next/PropertySearchContext'
import { PropertySearchContextProvider } from '../../next/PropertySearchContext'
import { PropertyFilter } from './PropertyFilter'
import { PropertyFilterOverviewSeoText } from './PropertyFilterOverviewRegionDescription'
import { PropertyFilterOverviewResultProperties } from './PropertyFilterOverviewResultProperties'
import { PropertyIndexFilterResultTitle } from './PropertyIndexFilterResultTitle'
import { TableSwitch } from './table/TableSwitch'

const PropertyFilterOverviewResultPropertiesTable = dynamic(
  () => import('./PropertyFilterOverviewResultPropertiesTable')
)

const PropertyIndexFilterArea = tw.div`hidden md:(block col-start-1 col-span-4) lg:(col-start-1 col-span-3)`
const PropertyIndexResultArea = tw.div`col-start-1 col-span-12 md:(col-start-5 col-span-8) lg:(col-start-4 col-span-9)`
export const PropertyIndexResultAreaInnerGrid = styled(GridWithGaps)(() => [
  tw`grid-cols-12 md:(grid-cols-8) lg:(grid-cols-9)`,
])

const PropertyIndexFilterPropertyResults = tw.div`col-start-1 col-span-12 -mt-px-96 md:(col-start-1 col-span-8 -mt-px-196) lg:(col-start-2 col-span-7)`

export const PropertyFilterOverview: FunctionComponent<React.PropsWithChildren<PropertySearchContextProviderOptions>> = (options) => {
  const [showTableView, setShowTableView] = useState(false)

  return (
    <PropertySearchContextProvider {...options}>
      <GridWithGaps>
        <TableSwitch
          showTableView={showTableView}
          setShowTableView={setShowTableView}
        />
        <PropertyIndexFilterArea>
          <PropertyFilter />
        </PropertyIndexFilterArea>
        <PropertyIndexResultArea>
          {showTableView ? (
            <PropertyFilterOverviewResultPropertiesTable />
          ) : (
            <PropertyIndexResultAreaInnerGrid>
              <PropertyIndexFilterResultTitle />
              <PropertyIndexFilterPropertyResults>
                <PropertyFilterOverviewResultProperties />
              </PropertyIndexFilterPropertyResults>
            </PropertyIndexResultAreaInnerGrid>
          )}
          <PropertyFilterOverviewSeoText seoText={options.seoText} />
        </PropertyIndexResultArea>
      </GridWithGaps>
    </PropertySearchContextProvider>
  )
}
