import { useTranslation } from '@dreamstack/i18n'
import { ArrowRight } from '@dreamstack/icons'
import { motion } from 'framer-motion'
import Image from 'next/legacy/image'
import Link from 'next/link'
import type { FunctionComponent } from 'react'
import 'twin.macro'
import tw, { styled } from 'twin.macro'
import {
  AnimateIconTranslationRight,
  GridWithGaps,
  HeadingH3,
  SimpleMuiButton,
  useAnimateIconTranslationRight,
  useProjectBaseUrl,
} from '../../index'

const ImageSrc = '/static/images/project-teaser.png'
// 'https://images.unsplash.com/photo-1505843513577-22bb7d21e455?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1490&q=80'

const PropertyIndexResultAreaInnerGrid = styled(GridWithGaps)(() => [
  tw`grid-cols-12 md:(grid-cols-8) lg:(grid-cols-9)`,
])

const PositionContainer = tw.div`col-start-1 col-span-12 md:(col-start-2 col-span-7)`

export const PropertyFilterProjectLink: FunctionComponent<
  React.PropsWithChildren<{}>
> = () => {
  const { onHoverStart, controls } = useAnimateIconTranslationRight()
  const t = useTranslation()
  const href = useProjectBaseUrl()
  return (
    <PropertyIndexResultAreaInnerGrid>
      <PositionContainer>
        <div tw="flex flex-col space-y-px-12 md:(flex-row space-y-0 space-x-px-32)">
          <div tw="h-px-96 w-px-96 relative md:(self-center)">
            <Image
              tw="object-cover"
              layout="fill"
              src={ImageSrc}
              alt={'project teaser image'}
            />
          </div>
          <div tw="flex flex-col md:(justify-center)">
            <HeadingH3>{t('accentro:projectLinkSection.title')}</HeadingH3>
            <Link href={href} passHref legacyBehavior>
              <motion.a onHoverStart={onHoverStart}>
                <SimpleMuiButton
                  variant="text"
                  color="primary"
                  endIcon={
                    <AnimateIconTranslationRight animate={controls}>
                      <ArrowRight />
                    </AnimateIconTranslationRight>
                  }
                >
                  <span tw="text-left">
                    {t('accentro:projectLinkSection.linkLabel')}
                  </span>
                </SimpleMuiButton>
              </motion.a>
            </Link>
          </div>
        </div>
      </PositionContainer>
    </PropertyIndexResultAreaInnerGrid>
  )
}
