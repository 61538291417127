import type { ReducedPropertyPreview } from '@dreamstack/web/lib/prisma/properties'
import filter from 'lodash/filter'
import first from 'lodash/first'
import type { PropstackImageSizes } from './propstack-image-url-transform'
import { transformPropstackImageUrl } from './propstack-image-url-transform'

export const getPreviewImageUrl = (
  { property_images }: Pick<ReducedPropertyPreview, 'property_images'>,
  size?: PropstackImageSizes
) => {
  const filteredImages = filter(property_images, (image) => !image.isFloorplan)
  let url = first(filteredImages)?.url
  url = transformPropstackImageUrl({ url, size }) ?? undefined
  return url
}
