import { useFormatNumber, useTranslation } from '@dreamstack/i18n'
import type { FunctionComponent } from 'react'
import { usePropertySearchContext } from '../..'
import { MaxFilterValue } from '../../next/PropertySearchFilter'

const formatNumberOptions = { maximumFractionDigits: 0 }

export const PropertyFilterTextSummary: FunctionComponent<React.PropsWithChildren<{}>> = () => {
  const { propertySearchFilterValues, noPlaceSelected } =
    usePropertySearchContext()
  const formatNumber = useFormatNumber(formatNumberOptions)
  const t = useTranslation()

  const minLivingSpaceSet = propertySearchFilterValues.livingspace[0] > 0
  const maxLivingSpaceSet =
    propertySearchFilterValues.livingspace[1] < MaxFilterValue.livingSpace
  const bothLivingSpaceSet = minLivingSpaceSet && maxLivingSpaceSet
  const anyLivingSpaceSet = minLivingSpaceSet || maxLivingSpaceSet
  const minPriceRangeSet = propertySearchFilterValues.priceRange[0] > 0
  const maxPriceRangeSet =
    propertySearchFilterValues.priceRange[1] < MaxFilterValue.price
  const bothPriceRangeSet = minPriceRangeSet && maxPriceRangeSet
  const anyPriceRangeSet = minPriceRangeSet || maxPriceRangeSet

  return (
    <>
      {!noPlaceSelected && (
        <>
          <span tw="font-normal">{t('accentro:filter.in')} </span>
          <span>
            {[
              ...propertySearchFilterValues.regions,
              ...propertySearchFilterValues.locations,
              ...propertySearchFilterValues.cities,
            ]
              .filter((e) => !!e)
              .join(', ')}
          </span>
          {(anyLivingSpaceSet || anyPriceRangeSet) && ', '}
        </>
      )}
      {bothLivingSpaceSet && (
        <span>
          {formatNumber(propertySearchFilterValues.livingspace[0])}
          {' - '}
          {formatNumber(propertySearchFilterValues.livingspace[1])}
          {t('accentro:squareMeter')}
        </span>
      )}
      {!bothLivingSpaceSet && minLivingSpaceSet && (
        <span>
          <span tw="font-normal">{t('accentro:filter.from')}</span>{' '}
          {formatNumber(propertySearchFilterValues.livingspace[0])}{' '}
          {t('accentro:squareMeter')}
        </span>
      )}
      {!bothLivingSpaceSet && maxLivingSpaceSet && (
        <span>
          <span tw="font-normal">{t('accentro:filter.to')}</span>{' '}
          {formatNumber(propertySearchFilterValues.livingspace[1])}{' '}
          {t('accentro:squareMeter')}
        </span>
      )}

      {anyLivingSpaceSet && anyPriceRangeSet && ', '}

      {bothPriceRangeSet && (
        <span>
          {formatNumber(propertySearchFilterValues.priceRange[0])}
          {' - '}
          {formatNumber(propertySearchFilterValues.priceRange[1])}
          {'EUR'}
        </span>
      )}
      {!bothPriceRangeSet && minPriceRangeSet && (
        <span>
          <span tw="font-normal">{t('accentro:filter.from')}</span>{' '}
          {formatNumber(propertySearchFilterValues.priceRange[0])} EUR
        </span>
      )}
      {!bothPriceRangeSet && maxPriceRangeSet && (
        <span>
          <span tw="font-normal">{t('accentro:filter.to')}</span>{' '}
          {formatNumber(propertySearchFilterValues.priceRange[1])} EUR
        </span>
      )}
    </>
  )
}
