import tw from 'twin.macro'
import { ContentfulRichText } from '../../../../accentro-contentful/src/contentful/ContentfulRichText'
import { PropertyIndexResultAreaInnerGrid } from './PropertyFilterOverview'

const RichTextWrapper = tw.div`col-span-full mt-px-24 lg:col-start-2`

type Props = {
  seoText?: { json: any }
}

export const PropertyFilterOverviewSeoText = (props: Props) => {
  if (!props.seoText) return null

  return (
    <PropertyIndexResultAreaInnerGrid>
      <RichTextWrapper>
        <ContentfulRichText richText={props.seoText} />
      </RichTextWrapper>
    </PropertyIndexResultAreaInnerGrid>
  )
}
