import {
  useFormatToCurrencyCutoff,
  useFormatToDecimal,
  useTranslation,
} from '@dreamstack/i18n'
import { ArrowRight } from '@dreamstack/icons'
import { getPropertyLocationLabel } from '@dreamstack/real-estate-logic'
import { ImageCover, SimpleDefinitionList } from '@dreamstack/simple-components'
import { useOnScreen } from '@dreamstack/util'
import type { ReducedPropertyPreview } from '@dreamstack/web/lib/prisma/properties'
import type { FunctionComponent } from 'react'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import tw, { styled } from 'twin.macro'
import {
  ReserveNowButton,
  useIsReservationUi,
  usePropertyPriceVisibility,
} from '..'
import {
  Css_HeadingH3,
  Css_SmallText,
  GridWithGaps,
  ScreenBreakpoints,
  SimpleIconButton,
  blurText,
  useIsMdOrGreater,
} from '../index'
import { MyLikeButton } from '../my/MyLikeButton'
import { getPropertyFallbackImageUrl } from '../utils/getPropertyFallbackImageUrl'
import { getPreviewImageUrl } from './property.util'
import { usePropertyTitle } from './usePropertyTitle'

const SHOW_TAG_TIMESPAN = 14

const PropertyPreviewDiv = tw.div`
flex
flex-col
w-full
p-px-24
relative
`

const ContentWrapper = styled.div<{ rightPosition: boolean }>(
  ({ rightPosition }) => [
    tw`col-span-6 -mt-px-48 lg:px-8 z-10 bg-accentroGray-full text-accentroWhite-full`,
    rightPosition && tw` col-start-2`,
    !rightPosition && tw` col-start-1`,
  ]
)

const ImageWrapper = tw.div`height[200px] md:(height[400px]) lg:(height[580px]) relative col-start-1 col-span-7`

const Location = styled.span(() => [
  Css_SmallText,
  tw` font-normal whitespace-nowrap overflow-hidden overflow-ellipsis pb-px-16 ml:(pb-px-24)`,
])

const PropertyTitle = styled.div(() => [
  Css_HeadingH3,
  tw`flex-1 overflow-hidden overflow-ellipsis`,
])

const NewTag = tw.div`absolute top-0 left-0 bg-accentroAqua-full text-accentroWhite-full p-px-16 py-px-4 text-center font-semibold -translate-y-3/4 text-px-16 leading-px-30 md:(text-px-20 leading-px-30 p-px-24 py-px-8)`

const PROPERTY_FALLBACK_IMG_URL = getPropertyFallbackImageUrl()

interface PropertyPreviewProps {
  property: ReducedPropertyPreview
  propDetailsLayoutPosition?: 'right' | 'left'
}

export const PropertyPreview: FunctionComponent<
  React.PropsWithChildren<PropertyPreviewProps>
> = ({ property, propDetailsLayoutPosition = 'right' }) => {
  const { number_of_rooms, property_space_value } = property
  const t = useTranslation()
  const formatDecimal = useFormatToDecimal()
  const formatCurrency = useFormatToCurrencyCutoff()
  const formatPriceCutoff = useFormatToCurrencyCutoff()
  const isMdOrGreater = useIsMdOrGreater()
  const ENABLE_PARALLAX_EFFECT = isMdOrGreater

  const propertyTitle = usePropertyTitle(property)
  const imgUrl =
    getPreviewImageUrl(property, 'big') || PROPERTY_FALLBACK_IMG_URL

  const resetScroll = useCallback(() => {
    const currentContainer = containerRef?.current
    if (!currentContainer) return
    currentContainer.style.transform = `translateY(0px)`
  }, [])

  const handleScroll = useCallback(() => {
    if (window.innerWidth <= ScreenBreakpoints.breakpointMd) return
    const currentContainer = containerRef?.current
    if (!currentContainer) return
    // if (!isMdOrGreater) return
    const containerProps = currentContainer.getBoundingClientRect()
    const top = containerProps.top
    const windowHeight = window.innerHeight
    if (top > windowHeight) return

    //get the max of 10% window height or 100px
    const scrollFactor = Math.min(windowHeight / 10, 100)

    const scrollValue = Math.floor((top / windowHeight - 1) * scrollFactor)

    currentContainer.style.transform = `translateY(${scrollValue}px)`
  }, [])

  const propertyPriceVisibility = usePropertyPriceVisibility()
  const priceVisibility = propertyPriceVisibility(property)
  const isReservationUi = useIsReservationUi()

  const safePrice =
    priceVisibility || isReservationUi
      ? formatPriceCutoff(property.price || 0)
      : '???.???'

  const safePrice_per_sqm =
    priceVisibility || isReservationUi
      ? formatDecimal(property?.price_per_sqm || 0)
      : '???'

  const containerRef = useRef<HTMLDivElement | null>(null)

  const isOnScreen = useOnScreen(containerRef)

  useEffect(() => {
    if (!isOnScreen || !ENABLE_PARALLAX_EFFECT) {
      if (!ENABLE_PARALLAX_EFFECT) resetScroll()
      window.removeEventListener('scroll', handleScroll)
    } else {
      window.addEventListener('scroll', handleScroll)
    }
  }, [isOnScreen, ENABLE_PARALLAX_EFFECT, handleScroll, resetScroll])

  const count = number_of_rooms || 0

  const locationLabel = getPropertyLocationLabel(property)

  const showNewTag = useMemo(() => {
    if (!property.first_published_at) return false
    const firstPublishedAt = new Date(property.first_published_at)

    const now = new Date()
    const showTagDate = new Date(firstPublishedAt)
    showTagDate.setDate(showTagDate.getDate() + SHOW_TAG_TIMESPAN)
    return showTagDate > now
  }, [property.first_published_at])

  return (
    <>
      <GridWithGaps tw="grid-cols-7 mb-px-80">
        <ImageWrapper>
          <ImageCover src={imgUrl} alt="property preview" />
        </ImageWrapper>
        <ContentWrapper
          rightPosition={propDetailsLayoutPosition === 'right'}
          ref={containerRef}
        >
          <PropertyPreviewDiv>
            {showNewTag && <NewTag>{t('accentro:new')}</NewTag>}
            <div tw="flex pb-px-8 ml:(pb-px-16 space-x-px-8)">
              <PropertyTitle title={propertyTitle}>
                {propertyTitle}
              </PropertyTitle>
              <div tw="ml:mt-px-4">
                <MyLikeButton property={property} propertyId={property.id} />
              </div>
            </div>
            <Location>{locationLabel}</Location>
            <div tw="flex flex-row justify-between flex-wrap">
              {/* <div tw="flex pb-px-12 md:(justify-between pb-0)"> */}
              <SimpleDefinitionList
                tw="mr-px-12 mb-px-12"
                dd={t('accentro:room.rooms', { count })}
                dt={count}
              />
              <SimpleDefinitionList
                tw="mr-px-12  mb-px-12"
                dd={t('accentro:living-space')}
                dt={`${formatDecimal(property_space_value || 0)} ${t(
                  'accentro:squareMeter'
                )}`}
              />
              <div
                css={
                  !priceVisibility && !isReservationUi ? blurText : undefined
                }
              >
                <SimpleDefinitionList
                  tw="mr-px-12 mb-px-12"
                  dt={`${safePrice} EUR`}
                  dd={`${safePrice_per_sqm} EUR / ${t('accentro:squareMeter')}`}
                />
              </div>
              <SimpleIconButton tw="border-white! text-accentroWhite-full">
                <ArrowRight tw="text-accentroWhite-full" />
              </SimpleIconButton>
            </div>
            {!!property.status && (
              <ReserveNowButton
                status={property.status}
                propId={property.id}
                isOnDarkBg
              />
            )}
          </PropertyPreviewDiv>
        </ContentWrapper>
      </GridWithGaps>
    </>
  )
}
