import { useTranslation } from '@dreamstack/i18n'
import { Compare } from '@dreamstack/icons'
import { Menu, MenuItem } from '@material-ui/core'
import find from 'lodash/find'
import map from 'lodash/map'
import type { FunctionComponent } from 'react'
import React, { useCallback, useMemo } from 'react'
import 'twin.macro'
import { SimpleMuiButton } from '../../index'
import { usePropertySearchContext } from '../../next/PropertySearchContext'

export const PropertyFilterSortMenu: FunctionComponent<
  React.PropsWithChildren<{}>
> = () => {
  const t = useTranslation()

  const { setPropertySearchFilterValues, propertySearchFilterValues } =
    usePropertySearchContext()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget)
    },
    [setAnchorEl]
  )

  const handleClose = useCallback(
    (newValue: any, value: any) => {
      setPropertySearchFilterValues({ sortBy: newValue, value: value })
      setAnchorEl(null)
    },
    [setAnchorEl, setPropertySearchFilterValues]
  )

  const SortByOptions: {
    label: string
    value: 'first_published_at' | 'price'
    sortDirection: 'asc' | 'desc'
    value_sortDir:
      | 'first_published_at_desc'
      | 'first_published_at_asc'
      | 'price_asc'
      | 'price_desc'
  }[] = useMemo(
    () => [
      {
        label: t('accentro:sortOptions.newest_first'),
        value: 'first_published_at',
        sortDirection: 'desc',
        value_sortDir: 'first_published_at_desc',
      },
      {
        label: t('accentro:sortOptions.oldest_first'),
        value: 'first_published_at',
        sortDirection: 'asc',
        value_sortDir: 'first_published_at_asc',
      },
      {
        label: t('accentro:sortOptions.cheapest_first'),
        value: 'price',
        sortDirection: 'asc',
        value_sortDir: 'price_asc',
      },
      {
        label: t('accentro:sortOptions.most_expensive_first'),
        value: 'price',
        sortDirection: 'desc',
        value_sortDir: 'price_desc',
      },
    ],
    [t]
  )
  const activeSort = useMemo(
    () =>
      find(
        SortByOptions,
        (option) => option.value_sortDir === propertySearchFilterValues.sortBy
      ),
    [SortByOptions, propertySearchFilterValues]
  )

  return (
    <div tw="flex items-center">
      <SimpleMuiButton
        aria-controls="propfilter-sortby-options"
        aria-haspopup="true"
        onClick={handleClick}
        variant="text"
      >
        <Compare tw="transform rotate-90 w-px-24 h-px-24 mr-px-8 md:(w-px-32 h-px-32)" />
        {t('accentro:sortBy')}: {activeSort?.label}{' '}
      </SimpleMuiButton>
      <Menu
        id="propfilter-sortby-options"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        // onClose={handleClose}
      >
        {map(SortByOptions, (sortbyOption) => {
          return (
            <MenuItem
              key={sortbyOption.value_sortDir}
              onClick={() =>
                handleClose(sortbyOption.value_sortDir, sortbyOption.value)
              }
            >
              {sortbyOption.label}
            </MenuItem>
          )
        })}
      </Menu>
    </div>
  )
}
