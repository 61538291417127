import type { IOtherLocaleUrlsContext} from '@dreamstack/i18n';
import { useLocale } from '@dreamstack/i18n'

const ProjectBaseUrls = {
  de: '/projekte',
  en: '/projects',
}

export const useProjectBaseUrl = () => {
  const locale = useLocale()
  return ProjectBaseUrls[locale]
}

export const projectsOtherLocaleUrls: IOtherLocaleUrlsContext = {
  de: { url: '/projekte' },
  en: { url: '/projects' },
}

export const comingSoonProjectOtherLocaleUrls: IOtherLocaleUrlsContext = {
  de: { url: '/comingsoon' },
  en: { url: '/comingsoon' },
}
