import { SimpleCheckbox } from '@dreamstack/simple-components'
import type { FunctionComponent } from 'react'
import tw from 'twin.macro'
import { useIsReservationUi } from '../../../next/useIsReservationUi'

const Container = tw.div`col-span-full flex flex-row justify-end`

export const TableSwitch: FunctionComponent<React.PropsWithChildren<{
  showTableView: boolean
  setShowTableView: (current: boolean) => void
}>> = ({ showTableView, setShowTableView }) => {
  const isReservationUi = useIsReservationUi()
  if (!isReservationUi) return null
  return (
    <Container>
      {/* TODO: style */}
      <SimpleCheckbox
        formControlProps={{ label: 'Tabellenansicht' }}
        checkBoxProps={{
          value: showTableView,
          onChange: (_, checked) => {
            setShowTableView(checked)
          },
        }}
      />
    </Container>
  )
}
