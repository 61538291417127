import type { FunctionComponent} from 'react';
import { useState } from 'react'
import tw from 'twin.macro'
import {
  useGetPropertiesFilteredAndSorted,
  useIsReservationUi,
} from '../../index'
import { PropertyFilterOverviewNoResults } from './PropertyFilterOverviewNoResults'
import { PropertyFilterOverviewResultHeading } from './PropertyFilterOverviewResultHeading'
import { PropertyFilterProjectLink } from './PropertyFilterProjectLink'

//TODO: FIX MOBILE and MD LAYOUTS
const PropertyIndexFilterResultTitleWrapper = tw.div`bg-accentroAquaVeryLight-full col-span-12 pt-px-48 pb-px-128 -mx-px-24 px-px-24 
space-y-px-24 md:(-mx-px-32 px-0 col-span-8 pt-px-64 space-y-px-48 padding-bottom[240px]) lg:(col-span-9 mx-0 pt-px-96 space-y-px-64 padding-bottom[240px])`

export const PropertyIndexFilterResultTitle: FunctionComponent<React.PropsWithChildren<{}>> = () => {
  const [mobileFilterOpen, setMobileFilterOpen] = useState(false)
  const { allPropertiesFilteredAndSorted, loading } =
    useGetPropertiesFilteredAndSorted()
  const isReservationUi = useIsReservationUi()

  const showNoResults =
    !loading && allPropertiesFilteredAndSorted?.length === 0 && !isReservationUi

  return (
    <PropertyIndexFilterResultTitleWrapper>
      <>
        {!showNoResults && <PropertyFilterProjectLink />}
        <PropertyFilterOverviewResultHeading
          mobileFilterOpen={mobileFilterOpen}
          setMobileFilterOpen={setMobileFilterOpen}
          showNoResults={showNoResults}
        />
        {showNoResults && <PropertyFilterOverviewNoResults />}
      </>
    </PropertyIndexFilterResultTitleWrapper>
  )
}
