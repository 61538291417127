import { useTranslation } from '@dreamstack/i18n'
import { useIsReservationUi } from '../next/useIsReservationUi'

export const usePropertyTitle = (property: {
  name: string
  title: string | null
}) => {
  const t = useTranslation()
  const isReservationUi = useIsReservationUi()
  const { name, title } = property
  let propertyTitle = ''
  if (isReservationUi) {
    const namePart = !!name ? `[${name}] ` : ''
    propertyTitle = `${namePart}${title || ''}`
  } else {
    propertyTitle = title || name
  }
  return propertyTitle || t('accentro:noTitle')
}
