import { useMemo } from 'react'
import { usePropertySearchContext } from '../next/PropertySearchContext'
import { useApplyPropertiesFilter } from './useApplyPropertiesFilter'

export const useGetPropertiesFilteredAndSorted = () => {
  const {
    propertySearchFilterValues,
    propertySearchReservationUiFilter,
  } = usePropertySearchContext()
  const { applyFilter, loading } = useApplyPropertiesFilter()

  const allPropertiesFilteredAndSorted = useMemo(() => {
    return applyFilter(
      propertySearchFilterValues,
      false,
      propertySearchReservationUiFilter
    )
  }, [
    propertySearchFilterValues,
    applyFilter,
    propertySearchReservationUiFilter,
  ])

  return { allPropertiesFilteredAndSorted, loading }
}
