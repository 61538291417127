import {
  PropertyStatus,
  PropertyStatusOpenReservation,
} from '@dreamstack/web-graphql'
import { useRouter } from 'next/router'
import type { FunctionComponent } from 'react'
import {
  useGetPropertyUrl,
  useIsReservationUi,
  useParsePropertyStatus,
} from '..'
import { SimpleMuiButton } from '../index'

export const ReserveNowButton: FunctionComponent<React.PropsWithChildren<{
  status: PropertyStatus
  propId: number
  isOnDarkBg?: boolean
}>> = ({ status, propId, isOnDarkBg = false }) => {
  const isReservationUi = useIsReservationUi()

  const router = useRouter()

  const getUrl = useGetPropertyUrl()
  const href = getUrl({ id: propId, city: undefined })
  const getPropertyStatus = useParsePropertyStatus()
  const propertyStatus = getPropertyStatus(status)

  const isReservable = status === PropertyStatus.FREE

  const isDisabled =
    !isReservable && !PropertyStatusOpenReservation.includes(status)

  return (
    <>
      {isReservationUi && (
        <SimpleMuiButton
          color="primary"
          disabled={isDisabled}
          fullWidth
          variant="contained"
          style={isOnDarkBg ? { color: 'white' } : {}}
          onClick={() => router.push(href)}
        >
          {isReservable ? 'Jetzt reservieren' : propertyStatus}
        </SimpleMuiButton>
      )}
    </>
  )
}
